import AuthService from '../../services/auth.service';

const user = JSON.parse(localStorage.getItem('latina-sam-user'));

const initialState = user
    ? {status: {loggedIn: true}, user}
    : {status: {loggedIn: false}, user: null};

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({commit}, user) {
            return AuthService.login(user).then((user) => {
                    commit('LOGIN_SUCCEEDED', user);
                    return Promise.resolve(user);
                },
                (error) => {
                    commit('LOGIN_FAILED');
                    return Promise.reject(error);
                });
        },
        logout({commit}) {
            return AuthService.logout().then(() => {
                commit('LOGOUT_SUCCEEDED');
            })
        },
        unauthorized({commit}) {
            commit('LOGIN_FAILED');
        }
    },
    mutations: {
        LOGIN_SUCCEEDED(state, user) {
            state.user = user;
            state.loggedIn = true
        },
        LOGIN_FAILED(state) {
            state.user = null;
            state.loggedIn = false
        },
        LOGOUT_SUCCEEDED(state) {
            state.user = null;
            state.loggedIn = false
        }
    }
}