import client from './client';

class SupplierService {

    getSuppliers(params) {
        return client.get('suppliers', params);
    }

    mergeSuppliers(supplier1, supplier2, shouldOverwriteSettings) {
        return client.put('suppliers/merge', {
            supplier1: supplier1,
            supplier2: supplier2,
            shouldOverwriteSettings: shouldOverwriteSettings
        })
    }

    updateSupplier(supplier) {
        return client.patch('suppliers/' + supplier.id, supplier);
    }

}

export default new SupplierService();