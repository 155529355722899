<script>
export default {
  methods: {
    getCurrentInstance() {
      const key = 'spirit2Instance';
      const localStorageItem = localStorage.getItem(key);

      try {
        const instance = JSON.parse(localStorageItem);
        return instance.code;

      }catch (e) {
        return 'beverages';
      }
    }
  }
}
</script>