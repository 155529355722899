import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */'../views/Benchmarks.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */'../views/auth/Login.vue')
    },
    {
        path: '/articles',
        name: 'Articles',
        component: () => import(/* webpackChunkName: "articles" */'../views/Articles.vue')
    },
    {
        path: '/articles/:latinaId',
        name: 'ViewArticle',
        component: () => import(/* webpackChunkName: "view_article" */'../views/ViewArticle.vue')
    },
    {
        path: '/benchmarks',
        name: 'Benchmarks',
        component: () => import(/* webpackChunkName: "articles" */'../views/Benchmarks.vue')
    },
    {
        path: '/prices',
        name: 'Prices',
        component: () => import(/* webpackChunkName: "prices" */'../views/Prices.vue')
    },
    {
        path: '/sale-prices',
        name: 'SalePrices',
        component: () => import(/* webpackChunkName: "prices" */'../views/SalePrices.vue')
    },
    {
        path: '/pricelists',
        name: 'Pricelists',
        component: () => import(/* webpackChunkName: "pricelists" */'../views/Pricelists.vue')
    },
    {
        path: '/pricelists/:id/records',
        name: 'PricelistRecords',
        component: () => import(/* webpackChunkName: "priceListRecords" */'../views/PriceListRecords.vue')
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */'../views/Users.vue')
    },
    {
        path: '/suppliers',
        name: 'Suppliers',
        component: () => import(/* webpackChunkName: "suppliers" */'../views/Suppliers.vue')
    },
    {
        path: '/currencies',
        name: 'Currencies',
        component: () => import(/* webpackChunkName: "suppliers" */'../views/Currencies.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const publicPages = [
        '/login',
        '/register'
    ];

    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('latina-sam-user');

    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next();
    }
});

export default router
