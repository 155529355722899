import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './modules/auth.module';
import { articles} from "./modules/articles.module";
import { prices } from "./modules/prices.module";
import { pricelists } from "./modules/pricelists.module";
import { currencies } from "./modules/currencies.module";
import { suppliers } from "./modules/suppliers.module";
import { pricelistRecords } from "./modules/pricelistRecords.module";
import { ancillaries } from "./modules/ancillaries.module";
import { users } from "./modules/users.module";
import {newPriceList} from "./modules/newPricelist.module";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    articles,
    prices,
    pricelistRecords,
    pricelists,
    newPriceList,
    users,
    ancillaries,
    suppliers,
    currencies
  }
})
