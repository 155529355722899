import client from './client';

class CurrencyService {

    getCurrencies(params) {
        return client.get('currencies', params);
    }

    updateCurrency(params) {
        return client.put('currencies/' + params.id, params)
    }

}

export default new CurrencyService();