import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'bootstrap';
import {BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Toast from "vue-toastification";

// Plugins
import vSelect from 'vue-select'
import MoneyFormat from 'vue-money-format';
import VueApexCharts from 'vue-apexcharts';

// Mixins
import dateMixin from './mixins/date';
import instanceMixin from './mixins/instance';
import updateAppMixin from "@/mixins/update-app";


Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Toast, {
    position: "bottom-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    transition: "Vue-Toastification__fade-move",
});


Vue.component('v-select', vSelect);
Vue.component('money-format', MoneyFormat);
Vue.component('apexchart', VueApexCharts)

Vue.mixin({
    mixins: [dateMixin, instanceMixin, updateAppMixin]
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
