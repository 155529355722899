import client from "./client";

class UsersService {

    getUsers() {
      return client.get('users');
    }

    createUser(user) {
        return client.post('users', user);
    }

    updateUser(user) {
        return client.put('users/' + user.id, user);
    }

    deactivateUser(userId) {
        return client.put('users/' + userId + '/deactivate');
    }
}

export default new UsersService();