import client from './client';


class ArticleService {

    getArticles(params) {
        return client.get('articles', params);
    }

    getArticleByLatinaId(latinaArticleId) {
        return client.get('articles/' + latinaArticleId);
    }

    searchArticles(term) {
        return client.get('articles/search?term=' + term);
    }

    uploadArticles(data) {
        const formData = new FormData();

        formData.append('file', data.file);

        return client.post( 'articles/import', formData, {
            'Content-type': "multipart/form-data"
        });
    }

    uploadPriceStatistics(data) {
        const formData = new FormData();

        formData.append('file', data.file);

        return client.post( 'articles/uploadPriceStatistics', formData, {
            'Content-type': "multipart/form-data"
        });
    }

    addPrice(data) {
        return client.post('articles/' + data.article_id + '/addPrice', data);
    }

    deActivateArticle(oldId, newId) {
        return client.put( 'articles/deactivate', {
            old: oldId,
            new: newId
        });
    }

    updateArticle(data) {
        return client.put('articles/' + data.id, data);
    }

    exportSalePrices() {
        return client.get('prices/export', []);
    }

}

export default new ArticleService();