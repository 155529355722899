import PricelistRecordsService from '../../services/pricelistRecords.service';

const initialState = {
    pricelistRecords: [],
    isFetching: true,
    currentPricelist: null,
    matchPercentage: null,
}

export const pricelistRecords = {
    namespaced: true,
    state: initialState,
    actions: {
        fetchPricelistRecords({commit}, priceListId) {

            commit('FETCHING_PRICES');

            return PricelistRecordsService.fetchPricesForPricelist(priceListId).then((response) => {
                commit('PRICES_FETCHED', response.data);
                commit('PERCENTAGE_FETCHED', response.percentage_match)
                return Promise.resolve(response);
            }, (error) => {
                console.log(error.toString());
            })
        },

        resetPriceRecords({commit}) {
            commit('RESET_PRICES');
        }
    },
    mutations: {
        RESET_PRICES(state) {
            state.isFetching = true;
            state.pricelistRecords = [];
        },
        FETCHING_PRICES(state) {
            state.isFetching = true;
            state.pricelistRecords = [];
        },
        PRICES_FETCHED(state, data) {
            state.pricelistRecords = data
            state.isFetching = false;
        },

        PERCENTAGE_FETCHED(state, matchPercentage) {
            state.matchPercentage = matchPercentage
        }


    }
}