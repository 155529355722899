import ArticleService from '../../services/article.service';

const initialState = {
    articleResults: [],
    isFetching: false,
    isSaved: false
}

export const newPriceList = {
    namespaced: true,
    state: initialState,
    actions: {
        searchArticles({commit}, term) {
            commit('STARTED_FETCHING');

            return ArticleService.searchArticles(term).then((response) => {
                commit('ARTICLE_SEARCH_RESULTS_FETCHED', response.data);
                commit('COMPLETED_FETCHING');
                return Promise.resolve(response);
            }, (error) => {
                alert(error.toString());
            })
        },
        clearArticleSearch({commit}) {
            commit('ARTICLE_SEARCH_RESULTS_FETCHED', []);
        }
    },
    mutations: {
        STARTED_FETCHING(state) {
            state.isFetching = true;
        },
        COMPLETED_FETCHING(state) {
            state.isFetching = false;
        },
        ARTICLE_SEARCH_RESULTS_FETCHED(state, articles) {
            state.articleResults = articles;
        }
    }
}