import ArticleService from '../../services/article.service';

const initialState = {
    articles: [],
    articleLastRow: 0,
    isLoading: false,
    modals: {
        isAddModalOpen: false,
        isPricesExportModalOpen: false,
        isEditArticleModalOpen: false,
        isPriceModalOpen: false,
        isStatisticsModalOpen: false,
        isFiltersModalOpen: false,
        isDeactivateModalOpen: false,
    },
    currentArticle: null,
    filterSupplier: null,
    filterSupplierMaxPosition: null,
    shouldRefresh: false,
}

export const articles = {
    namespaced: true,
    state: initialState,
    actions: {
        fetchArticles({commit}, params) {
            commit("FETCHING_ARTICLES");

            return ArticleService.getArticles(params).then((response) => {
                commit('ARTICLES_FETCHED', response);
                commit('FETCHING_COMPLETE');
                return Promise.resolve(response);
            }, (error) => {
                commit('FETCHING_COMPLETE');
                alert(error.toString());
            })
        },
        fetchSingleArticle({commit}, latinaArticleId) {
          commit("FETCHING_ARTICLES");

            return ArticleService.getArticleByLatinaId(latinaArticleId).then((response) => {
                commit('ARTICLE_FETCHED', response);
                commit('FETCHING_COMPLETE');
                return Promise.resolve(response);
            }, (error) => {
                commit('FETCHING_COMPLETE');
                alert(error.toString());
            })

        },
        uploadArticles({commit}, data) {
            return ArticleService.uploadArticles(data).then((response) => {
                commit('ADD_MODAL_CLOSED');
                return Promise.resolve(response);
            }, (error) => {
                console.log(error.toString());
            });
        },
        uploadPurchaseStatistics({commit}, data) {
            return ArticleService.uploadPriceStatistics(data).then((response) => {
                commit('STATISTICS_MODAL_CLOSED');
                return Promise.resolve(response);
            }, (error) => {
                console.log(error.toString());
            });
        },
        addArticlePrice({commit}, data) {
            commit('ADDING_PRICE');

            ArticleService.addPrice(data).then((response) => {
                return Promise.resolve(response);
            }, (error) => {
                console.log(error.toString());
                return Promise.reject(error.toString());
            });
        },
        updateArticle({commit}, data) {
            commit('EDITING_ARTICLE');

            ArticleService.updateArticle(data).then((response) => {
                commit('EDITING_COMPLETED');
                return Promise.resolve(response);
            }, (error) => {
                console.log(error.toString());
                return Promise.reject(error.toString());
            });
        },
        applySupplierFilter({commit}, data) {
          commit('SUPPLIER_FILTER_APPLIED', data);
        },
        clearSupplierFilter({commit}) {
            commit('SUPPLIER_FILTER_CLEARED');
        },
        openAddModal({commit}) {
            commit("ADD_MODAL_OPENED");
        },
        closeAddModal({commit}) {
            commit("ADD_MODAL_CLOSED");
        },
        openPriceModal({commit}, article) {
            commit("PRICE_MODAL_OPENED", article);
        },
        closePriceModal({commit}) {
            commit("PRICE_MODAL_CLOSED");
        },
        openEditArticleModal({commit}, article) {
            commit("EDIT_ARTICLE_MODAL_OPENED", article);
        },
        closeEditArticleModal({commit}) {
            commit("EDIT_ARTICLE_MODAL_CLOSED");
        },
        openStatisticsUploadModal({commit}) {
            commit("STATISTICS_MODAL_OPENED");
        },
        closeStatisticsUploadModal({commit}) {
            commit("STATISTICS_MODAL_CLOSED");
        },
        openFiltersModal({commit}) {
            commit("FILTERS_MODAL_OPENED");
        },
        closeFiltersModal({commit}) {
            commit("FILTERS_MODAL_CLOSED");
        },
        openDeactivateModal({commit}, article) {
            commit("DEACTIVATE_MODAL_OPENED", article);
        },
        closeDeactivateModal({commit}) {
            commit("DEACTIVATE_MODAL_CLOSED");
        },
        openSalePriceExportModal({commit}) {
            commit("PRICES_EXPORT_MODAL_OPENED");
        },
        closeSalePriceExportModal({commit}) {
            commit("PRICES_EXPORT_MODAL_CLOSED");
        },
        deActivateArticle({commit}, data) {
            commit('DEACTIVATING_ARTICLE');

            ArticleService.deActivateArticle(data.oldArticleId, data.newArticleId).then((response) => {
                commit('ARTICLE_DEACTIVATED');
                return Promise.resolve(response);
            }, (error) => {
                console.log(error.toString());
                return Promise.reject(error.toString());
            });

        },
        exportSalePrices({commit}) {
            ArticleService.exportSalePrices().then((response) => {
                commit('PRICES_EXPORTED');
                return Promise.resolve(response);
            }, (error) => {
                console.log(error.toString());
                return Promise.reject(error.toString());
            });
        },
        triggerRefresh({commit}) {
            commit('REFRESHING');
        }
    },
    mutations: {
        FETCHING_ARTICLES(state) {
          state.isLoading = true;
        },
        ARTICLES_FETCHED(state, articles) {
            state.articles = articles.data;
            state.articleLastRow = articles.lastRow;
        },
        ARTICLE_FETCHED(state, article) {
            state.currentArticle = article.data;
        },
        FETCHING_COMPLETE(state) {
            state.isLoading = false;
        },
        ADD_MODAL_OPENED(state) {
            state.modals.isAddModalOpen = true;
        },
        ADD_MODAL_CLOSED(state) {
            state.modals.isAddModalOpen = false;
        },
        FILTERS_MODAL_OPENED(state) {
            state.modals.isFiltersModalOpen = true;
        },
        FILTERS_MODAL_CLOSED(state) {
            state.modals.isFiltersModalOpen = false;
        },
        STATISTICS_MODAL_OPENED(state) {
            state.modals.isStatisticsModalOpen = true;
        },
        STATISTICS_MODAL_CLOSED(state) {
            state.modals.isStatisticsModalOpen = false;
        },
        PRICE_MODAL_OPENED(state, article) {
            state.modals.isPriceModalOpen = true;
            state.currentArticle = article;
        },
        PRICE_MODAL_CLOSED(state) {
            state.modals.isPriceModalOpen = false;
            state.currentArticle = null;
        },
        EDIT_ARTICLE_MODAL_OPENED(state, article) {
            state.modals.isEditArticleModalOpen = true;
            state.currentArticle = article;
        },
        EDIT_ARTICLE_MODAL_CLOSED(state) {
            state.modals.isEditArticleModalOpen = false;
            state.currentArticle = null;
        },
        ADDING_PRICE(state) {
            state.isLoading = true
        },
        EDITING_ARTICLE(state) {
            state.isLoading = true
        },
        EDITING_COMPLETED(state) {
            state.isLoading = false
            state.currentArticle = null
            state.shouldRefresh = !state.shouldRefresh
        },
        PRICE_ADDED(state) {
            state.isLoading = false;
            state.currentArticle = false
        },
        SUPPLIER_FILTER_APPLIED(state, data) {
            state.isLoading = true;
            state.filterSupplier = data.supplier;
            state.filterSupplierMaxPosition = data.maxPricePosition
        },
        SUPPLIER_FILTER_CLEARED(state) {
            state.isLoading = true;
            state.filterSupplier = null;
            state.filterSupplierMaxPosition = null;
        },
        DEACTIVATE_MODAL_OPENED(state, article) {
            state.modals.isDeactivateModalOpen = true;
            state.currentArticle = article;
        },
        DEACTIVATE_MODAL_CLOSED(state) {
            state.modals.isDeactivateModalOpen = false;
            state.currentArticle = null;
        },
        PRICES_EXPORT_MODAL_OPENED(state) {
            state.modals.isPricesExportModalOpen = true;
        },
        PRICES_EXPORT_MODAL_CLOSED(state) {
            state.modals.isPricesExportModalOpen = false;
        },
        DEACTIVATING_ARTICLE(state) {
            state.isLoading = true;
        },
        ARTICLE_DEACTIVATED(state) {
            state.modals.isDeactivateModalOpen = false;
            state.shouldRefresh = true;
            state.currentArticle = null;
        },
        REFRESHING(state) {
            state.shouldRefresh = !state.shouldRefresh;
        },
    }
}