import client from './client';

class AuthService {
    login(user) {
        return client
            .post( 'login', {
                email: user.email,
                password: user.password
            })
            .then(data => {
                if (data.token) {
                    localStorage.setItem('latina-sam-user', JSON.stringify(data));
                }

                return data;
            });
    }

    logout() {
        localStorage.removeItem('latina-sam-user');

        return client
            .post('logout')
            .then(() => {
                localStorage.removeItem('latina-sam-user');
            });
    }

    register(user) {
        return client.post('register', {
            email: user.email,
            name: user.name,
            password: user.password
        });
    }
}

export default new AuthService();
