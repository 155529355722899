import SupplierService from '../../services/suppliers.service';

const initialState = {
    suppliers: [],
    isLoading: false,
}

export const ancillaries = {
    namespaced: true,
    state: initialState,
    actions: {
        fetchSuppliers({commit}, params) {
            commit("FETCHING_ANCILLARIES");

            return SupplierService.getSuppliers(params).then((response) => {
                commit('SUPPLIERS_FETCHED', response.data);
                commit('FETCHING_COMPLETE');
                return Promise.resolve(response);
            }, (error) => {
                commit('FETCHING_COMPLETE');
                alert(error.toString());
            })
        }
    },
    mutations: {
        FETCHING_ANCILLARIES(state) {
            state.isLoading = true;
        },
        SUPPLIERS_FETCHED(state, suppliers) {
            state.suppliers = suppliers;
        },
        FETCHING_COMPLETE(state) {
            state.isLoading = false;
        }
    }
}