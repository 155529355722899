export const fetchThenDispatch = function(action, commit, mutateIfPassed, commitIfFailed)
{
    action.then((response) => {
        if (response.data) {
            commit(mutateIfPassed, response.data);
            return Promise.resolve(response);
        }
    }, (error) => {
        console.log(error.toString());

        if (commitIfFailed) {
            commit(commitIfFailed, error.toString());
        }
    })
}