import {fetchThenDispatch} from "../utils/fetchThenDispatch";
import CurrenciesService from "../../services/currencies.service";

const initialState = {
    currencies: [],
    isFetching: true,
    selectedCurrency: null,
    modals: {
        isCurrencyModalOpen: false
    },
    error: null,
}

export const currencies = {
    namespaced: true,
    state: initialState,
    actions: {
        fetchCurrencies({commit}) {
            commit('FETCHING_CURRENCIES');
            return fetchThenDispatch(CurrenciesService.getCurrencies(), commit, 'CURRENCIES_FETCHED', 'REQUEST_ERROR')
        },
        openCurrencyModal({commit}, currencyId) {
            commit('FETCHING_CURRENCIES');
            if (currencyId) {
                commit('SELECT_CURRENCY', currencyId);
            } else {
                commit('DESELECT_CURRENCY');
            }
            commit('CURRENCY_MODAL_OPENED')
        },
        closeCurrencyModal({commit}) {
            commit('DESELECT_CURRENCY');
            commit('CURRENCY_MODAl_CLOSED')
        },

        updateCurrency({commit}, currency) {
            commit('FETCHING_CURRENCIES');
            CurrenciesService.updateCurrency(currency).then(() => {
                commit('DESELECT_CURRENCY')
            })
        }

    },
    mutations: {
        FETCHING_CURRENCIES(state) {
            state.error = null;
            state.isFetching = true;
        },
        CURRENCIES_FETCHED(state, currencies) {
            state.currencies = currencies;
            state.error = null;
            state.isFetching = false;
        },
        REQUEST_ERROR(state, errorMessage) {
            state.isFetching = false;
            state.error = errorMessage
        },
        SELECT_CURRENCY(state, currencyId) {
            state.selectedCurrency = state.currencies.find((item) => item.id === currencyId);
            state.error = null;
            state.isFetching = false;
        },
        DESELECT_CURRENCY(state) {
            state.selectedCurrency = null;
            state.error = null;
            state.isFetching = false;
        },
        CURRENCY_MODAL_OPENED(state) {
            state.modals.isCurrencyModalOpen = true;
        },
        CURRENCY_MODAl_CLOSED(state) {
            state.modals.isCurrencyModalOpen = false;
        },
    }
}