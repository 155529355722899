import SupplierService from '../../services/suppliers.service';
import {fetchThenDispatch} from "../utils/fetchThenDispatch";

const initialState = {
    suppliers: [],
    isFetching: true,
    currentSupplier: null,
    modals: {
        isSupplierModalOpen: false,
        isMergeModalOpen: false
    },
    error: null,
}

export const suppliers = {
    namespaced: true,
    state: initialState,
    actions: {
        fetchSuppliers({commit}) {
            commit('FETCHING_SUPPLIERS');
            return fetchThenDispatch(SupplierService.getSuppliers(), commit, 'SUPPLIERS_FETCHED', 'REQUEST_ERROR')
        },
        openSupplierModal({commit}, supplierId) {
            commit('FETCHING_SUPPLIERS');
            if (supplierId) {
                commit('SELECT_SUPPLIER', supplierId);
            } else {
                commit ('DESELECT_SUPPLIER');
            }
            commit('SUPPLIER_MODAl_OPENED')
        },
        closeSupplierModal({commit}) {
            commit ('DESELECT_SUPPLIER');
            commit('SUPPLIER_MODAl_CLOSED')
        },
        openSupplierMergeModal({commit}, supplierId) {
            commit('FETCHING_SUPPLIERS');
            if (supplierId) {
                commit('SELECT_SUPPLIER', supplierId);
            } else {
                commit ('DESELECT_SUPPLIER');
            }
            commit('SUPPLIER_MERGE_MODAL_OPENED')
        },
        updateSupplier({commit}, supplier) {
          commit('FETCHING_SUPPLIERS');
            SupplierService.updateSupplier(supplier).then(() => {
                commit ('DESELECT_SUPPLIER');
                commit('SUPPLIER_MODAl_CLOSED')
                return fetchThenDispatch(SupplierService.getSuppliers(), commit, 'SUPPLIERS_FETCHED', 'REQUEST_ERROR')
            });
        },
        updateSupplierAndOpenNext({commit}, supplier) {
            commit('FETCHING_SUPPLIERS');
            SupplierService.updateSupplier(supplier).then(() => {
                commit ('DESELECT_SUPPLIER');
                commit('SELECT_NEXT_SUPPLIER', supplier.id)
                return fetchThenDispatch(SupplierService.getSuppliers(), commit, 'SUPPLIERS_FETCHED', 'REQUEST_ERROR')
            });

        },
        mergeSuppliers({commit}, {supplier1, supplier2, shouldOverwriteSettings}) {
            commit('FETCHING_SUPPLIERS');
            SupplierService.mergeSuppliers(supplier1, supplier2, shouldOverwriteSettings).then(() => {
                commit ('DESELECT_SUPPLIER');
                commit('SUPPLIER_MERGE_MODAL_CLOSED')
                return fetchThenDispatch(SupplierService.getSuppliers(), commit, 'SUPPLIERS_FETCHED', 'REQUEST_ERROR')
            });
        },
        closeSupplierMergeModal({commit}) {
            commit ('DESELECT_SUPPLIER');
            commit('SUPPLIER_MERGE_MODAL_CLOSED')
        }
    },
    mutations: {
        FETCHING_SUPPLIERS(state) {
            state.error = null;
            state.isFetching = true;
        },
        SUPPLIERS_FETCHED(state, suppliers) {
            state.suppliers = suppliers;
            state.error = null;
            state.isFetching = false;
        },
        SELECT_SUPPLIER(state, supplierId) {
            state.currentSupplier = state.suppliers.find((item) => item.id === supplierId);
            state.error = null;
            state.isFetching = false;
        },
        DESELECT_SUPPLIER(state) {
            state.currentSupplier = null;
            state.error = null;
            state.isFetching = false;
        },
        REQUEST_ERROR(state, errorMessage) {
            state.isFetching = false;
            state.error = errorMessage
        },
        SUPPLIER_MODAl_OPENED(state) {
            state.modals.isSupplierModalOpen = true;
        },
        SUPPLIER_MODAl_CLOSED(state) {
            state.modals.isSupplierModalOpen = false;
        },
        SUPPLIER_MERGE_MODAL_OPENED(state) {
            state.modals.isMergeModalOpen = true;
        },
        SUPPLIER_MERGE_MODAL_CLOSED(state) {
            state.modals.isMergeModalOpen = false;
        },
        SELECT_NEXT_SUPPLIER(state, supplierId) {
            const currentSupplierIndex  = state.suppliers.findIndex((item) => item.id === supplierId);
            state.currentSupplier = state.suppliers[currentSupplierIndex + 1];
            state.modals.isSupplierModalOpen = true;
        }
    }
}