<script>
export default {
  methods: {
    getReadableDifference (timestamp) {

      let created = timestamp;

      // The time now
      let now = new Date().getTime();

      // The difference between now and created
      let howLongAgo = created - now;

      let getHumanTime = function (timestamp) {

        // Convert to a positive integer
        let time = Math.abs(timestamp);

        // Define humanTime and units
        let humanTime, units;

        /*// If there are years
        if (time > (1000 * 60 * 60 * 24 * 365)) {
          humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 365), 10);
          units = 'yrs';
        }

        // If there are months
        else */

        // If there are months
        if (time > (1000 * 60 * 60 * 24 * 30)) {
          humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 30), 10);
          units = 'mths';
        }

        // If there are weeks
        else if (time > (1000 * 60 * 60 * 24 * 7)) {
          humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 7), 10);
          units = 'wks';
        }

        // If there are days
        else if (time > (1000 * 60 * 60 * 24)) {
          humanTime = parseInt(time / (1000 * 60 * 60 * 24), 10);
          units = 'd';
        }

        // If there are hours
        else if (time > (1000 * 60 * 60)) {
          humanTime = parseInt(time / (1000 * 60 * 60), 10);
          units = 'hrs';
        }

        // If there are minutes
        else if (time > (1000 * 60)) {
          humanTime = parseInt(time / (1000 * 60), 10);
          units = 'min';
        }

        // Otherwise, use seconds
        else {
          humanTime = parseInt(time / (1000), 10);
          units = 's';
        }

        // Get the time and units
        let timeUnits = humanTime + ' ' + units;

        // If in the future
        if (timestamp > 0) {
          return 'in ' + timeUnits;
        }

        // If in the past
        return timeUnits ;

      };

      return getHumanTime(howLongAgo);
    },
    formatDate(value) {
      return value.toISOString().slice(0,10);
    }
  }
}

</script>