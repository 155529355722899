import client from "./client";

class PricelistService {

    getPriceLists() {
      return client.get('pricelists');
    }

    uploadPriceList(data) {
        const formData = new FormData();

        formData.append('supplier', data.supplier);
        formData.append('file', data.file);
        formData.append('currency', data.currency);
        formData.append('price_date', data.price_date);

        return client.post( 'pricelists', formData, {
            'Content-type': "multipart/form-data"
        });
    }

    uploadCustomPricelist(data) {
        const formData = new FormData();

        formData.append('template', JSON.stringify(data.template));
        formData.append('supplier_id', data.supplier.id);
        formData.append('supplier_name', data.supplier.name);
        formData.append('file', data.file);
        formData.append('expiry', data.expiry);
        formData.append('currency', data.currency);
        formData.append('price_date', data.price_date);
        formData.append('price_list_type', data.price_list_type);
        formData.append('force_supplier_category_override', data.force_supplier_category_override);
        formData.append('force_product_type_override', data.force_product_type_override);

        return client.post( 'pricelists/custom', formData, {
            'Content-type': "multipart/form-data"
        });
    }

    reProcessPricelist(id) {
        return client.put('pricelists/' + id + '/reprocess');
    }

    deletePriceList(id) {
        return client.delete('pricelists/' + id);
    }
}

export default new PricelistService();