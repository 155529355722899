import UsersService from '../../services/users.service';

const initialState = {
    users: [],
    isFetching: true,
    currentUser: null,
    modals: {
        isAddModalOpen: false
    }
}

export const users = {
    namespaced: true,
    state: initialState,
    actions: {
        fetchUsers({commit}) {
            commit('FETCHING_USERS');

            UsersService.getUsers().then((response) => {
                commit('USERS_FETCHED', response.data);
                return Promise.resolve(response);
            }, (err) => {
                alert(err)
            });
        },
        createUser({commit}, user) {
            UsersService.createUser(user).then((response) => {
                commit('ADD_MODAL_CLOSED')
                return Promise.resolve(response);
            }, (err) => {
                alert(err)
            })
        },

        updateUser({commit}, user) {
            UsersService.updateUser(user).then((response) => {
                commit('ADD_MODAL_CLOSED')
                return Promise.resolve(response);
            }, (err) => {
                alert(err)
            })
        },
        deactivateUser({commit}, user) {
            let decision = window.confirm("This will revoke this user's access to the system. Are you sure?");

            if (!decision) {
                return;
            }
            UsersService.deactivateUser(user).then((response) => {
                commit('ADD_MODAL_CLOSED')
                return Promise.resolve(response);
            }, (err) => {
                alert(err);
            })
        },
        openAddModal({commit}, userId = null) {
            if (userId !== null) {
                commit("USER_SELECTED", userId);
            } else {
                commit("USER_DESELECTED");
            }

            commit("ADD_MODAL_OPENED");
        },
        closeAddModal({commit}) {
            commit("ADD_MODAL_CLOSED");
        },
    },
    mutations: {
        FETCHING_USERS(state) {
            state.isFetching = true;
        },
        USERS_FETCHED(state, users) {
            state.users = users;
            state.isFetching = false;
        },
        USER_DESELECTED(state,) {
            state.currentUser = null;
        },
        USER_SELECTED(state, userId) {
            state.currentUser = state.users.find(user => user.id === userId);
        },
        ADD_MODAL_OPENED(state) {
            state.modals.isAddModalOpen = true;
        },
        ADD_MODAL_CLOSED(state) {
            state.modals.isAddModalOpen = false;
        }
    }
}